import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { routerMiddleware } from "react-router-redux"
import { createLogger } from "redux-logger"
import { loadingBarMiddleware } from 'react-redux-loading-bar'

export default function configureStore(history) {
    const logger = createLogger();
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(
        rootReducer,
        composeEnhancer(
        applyMiddleware(thunk,
            logger,
            loadingBarMiddleware({
                promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
            }),
            routerMiddleware(history)))
    );
}
