import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {ListCategories} from "./categories.actions";
import {CategoriesTable} from "./categories.table";

const CategoriesHeader = () => {
    return(
        <div className="c-members__header">
            <div className="c-members__header-header">
                <h4>Категории</h4>
            </div>
            <div className="c-members__header-button">
            </div>
        </div>
    );
}

const Categories = () => {
    const categories = useSelector(state => state.categories);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        if (!isLoading){
            dispatch(ListCategories());
            setIsLoading(true);
        }
    })

    useEffect(() => { console.log(categories) }, [categories])

    return(
        <div className="c-members">
            <CategoriesHeader/>
            {
                categories && <CategoriesTable items={categories.list}/>
            }
        </div>
    );
}

export default Categories;
