import axios from 'axios';
import { config } from '../../config';
import { AdvertisingActionTypes } from './advertising.types';

export function GetAdvertisingData(id) {
    return (dispatch) => {
        dispatch({
            type: AdvertisingActionTypes.GET.REQUEST,
            payload: {}
        });

        axios.get(config.apiUrl + config.methods.advertisingBase + '/' + id)
            .then(function (response) {
                dispatch({
                    type: AdvertisingActionTypes.GET.SUCCESS,
                    payload: response.data
                })
            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";

                dispatch({
                    type: AdvertisingActionTypes.GET.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function ListAdvertisingData() {
    return (dispatch) => {
        dispatch({
            type: AdvertisingActionTypes.LIST.REQUEST,
            payload: {}
        });

        axios.get(config.apiUrl + config.methods.advertisingList)
            .then(function (response) {
                dispatch({
                    type: AdvertisingActionTypes.LIST.SUCCESS,
                    payload: response.data
                })
            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";
                dispatch({
                    type: AdvertisingActionTypes.LIST.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function DeleteAdvertising(id) {
    return (dispatch) => {
        dispatch({
            type: AdvertisingActionTypes.DELETE.REQUEST,
            payload: {}
        })

        axios.delete(config.apiUrl + config.methods.advertisingBase + '/' + id)
            .then(function (response) {
                dispatch(ListAdvertisingData())
                dispatch({
                    type: AdvertisingActionTypes.DELETE.SUCCESS,
                    payload: id
                })
            })
            .catch(function (error) {
                let errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка"

                dispatch({
                    type: AdvertisingActionTypes.DELETE.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function AddAdvertising(file, name, description, isActive, history) {
    return (dispatch) => {
        dispatch({
            type: AdvertisingActionTypes.ADD.REQUEST,
            payload: {}
        })

        let form = new FormData();
        if (file){
            form.append('fileName', file.name);
            form.append('image', file);
        }
        form.append('name', name);
        form.append('description', description);
        form.append('isActive', isActive);

        axios.post(config.apiUrl + config.methods.advertisingBase, form)
            .then(function (response) {
                history.push("/advertising")
                dispatch({
                    type: AdvertisingActionTypes.ADD.SUCCESS,
                    payload: {}
                });

            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";

                dispatch({
                    type: AdvertisingActionTypes.ADD.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function UpdateAdvertising(id, file, name, description, isActive, history) {
    return (dispatch) => {
        dispatch({
            type: AdvertisingActionTypes.UPDATE.REQUEST,
            payload: {}
        })

        let form = new FormData();
        if (file){
            form.append('fileName', file.name);
            form.append('image', file);
        }else{
            form.append('fileName', '');
            form.append('image', '');
        }

        form.append('name', name);
        form.append('description', description);
        form.append('isActive', isActive);

        axios.put(config.apiUrl + config.methods.advertisingBase + '/' + id, form)
            .then(function (response) {
                dispatch(ListAdvertisingData())
                history.push("/advertising")
                dispatch({
                    type: AdvertisingActionTypes.UPDATE.SUCCESS,
                    payload: {}
                });
            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";
                dispatch(ListAdvertisingData())
                history.push("/advertising")
                dispatch({
                    type: AdvertisingActionTypes.UPDATE.ERROR,
                    payload: errorMessage
                })
            });
    }
}
