import {DictionaryTypes} from "./dictionary.types";

const INITIAL_STATE = {
    nomenclature: [],
    error: false,
    errorText: "",
    fetching: false,
}

const dictionaryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case DictionaryTypes.NOMENCLATURE_LIST.REQUEST:
            return {
                ...state,
                fetching: true
            };
        case DictionaryTypes.NOMENCLATURE_LIST.SUCCESS:
            return {
                ...state,
                nomenclature: action.payload,
                fetching: false
            };
        case DictionaryTypes.NOMENCLATURE_LIST.ERROR:
            return {
                ...state,
                error: true,
                errorText: action.payload,
                fetching: false
            };
        default:
            return state;
    }
}

export default dictionaryReducer;
