import { useSelector, useDispatch } from 'react-redux';
import {useEffect, useState} from "react";
import {NotificationTable} from "./notification.table";
import {Link} from "react-router-dom";
import {ListNotificationData} from "./notification.actions";

const NotificationHeader = () => {
    return(
        <div className="c-members__header">
            <div className="c-members__header-header">
                <h4>Уведомления</h4>
            </div>
            <div className="c-members__header-button">
                <Link className="e-btn e-btn--filled" to="/notification/create">Добавить</Link>
            </div>
        </div>
    );
}

const Notification = () => {
    const notifications = useSelector(state => state.notifications);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        if (!isLoading){
            dispatch(ListNotificationData());
            setIsLoading(true);
        }
    })

    return(
        <div className="c-members">
            <NotificationHeader/>
            <NotificationTable items={notifications.list}/>
        </div>
    );
}

export default Notification;
