import React, {useState} from "react";
import {useDispatch} from "react-redux";
import { loginAction } from './auth.actions';
import InputPublic from "../../components/inputPublic";
import InputPrivate from "../../components/inputPrivate";
import {Link} from "react-router-dom";

const AuthComponent = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [btnLoginDisabled, setBtnLoginDisabled] = useState(true);
    const dispatch = useDispatch();

    const submitLogin = () => {
        dispatch(loginAction(email, password));
    }

    const handleLoginInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name === 'email'){
            setEmail(value);
        }else if (name === 'password'){
            setPassword(value);
        }

        if (name && password){
            setBtnLoginDisabled(false);
        }else{
            setBtnLoginDisabled(true);
        }
    }

    return(
        <div className="row">
            <div className="col-md-2">
                <div className="left-side">
                    <div className="mobile-burger">
                        <div className="mobile-burger__head">
                            <button type="button" className="mobile-burger__btn js-close-mmenu"><span></span></button>
                        </div>
                        <div className="mobile-burger__content">
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-sm-10">
                <div className="main-content" style={{textAlign: 'center'}}>
                    <h3>Вход в систему</h3>
                    <div className="c-entry">
                        <form id="c-entry__login" className="c-entry__login-form">
                            <div className="c-entry__line">
                                <InputPublic
                                    title={'Email'}
                                    name={'email'}
                                    type={'email'}
                                    placeholder={''}
                                    data={email}
                                    onChange={handleLoginInputChange}
                                />
                            </div>
                            <div className="c-entry__line">
                                <InputPrivate
                                    title={'Пароль'}
                                    name={'password'}
                                    type={'text'}
                                    isShowButton={true}
                                    data={password}
                                    onChange={handleLoginInputChange}
                                />
                            </div>
                            <div className="c-entry__line c-entry__line--mt">
                                <button type="button" className="e-btn e-btn--filled" onClick={submitLogin}>Войти</button>
                            </div>
                            <div className="c-entry__line">
                                <Link className="e-link e-link--login" to="/forgot">Забыли пароль?</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuthComponent;
