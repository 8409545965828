import {CategoriesTypes} from "./categories.types";
import axios from "axios";
import {config} from "../../config";

export function ListCategories() {
    return (dispatch) => {
        dispatch({
            type: CategoriesTypes.LIST.REQUEST,
            payload: {}
        });

        axios.get(config.apiUrl + config.methods.categoryList)
            .then(function (response) {
                dispatch({
                    type: CategoriesTypes.LIST.SUCCESS,
                    payload: response.data
                })
            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";
                dispatch({
                    type: CategoriesTypes.LIST.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function UpdateCategory(id, file, selectedFile) {
    return (dispatch) => {
        dispatch({
            type: CategoriesTypes.UPDATE.REQUEST,
            payload: {}
        })

        let form = new FormData();
        if (file){
            form.append('image', file);
            form.append('fileName', file.name);
        }else{
            form.append('image', '');
            form.append('fileName', '');
        }

        if (selectedFile){
            form.append('selectedImage', selectedFile);
            form.append('selectedFileName', selectedFile.name);
        }else{
            form.append('selectedImage', '');
            form.append('selectedFileName', '');
        }

        axios.put(config.apiUrl + config.methods.categoryUpdate + '/' + id, form)
            .then(function (response) {
                dispatch(ListCategories())
                dispatch({
                    type: CategoriesTypes.UPDATE.SUCCESS,
                    payload: {}
                });
            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";

                dispatch({
                    type: CategoriesTypes.UPDATE.ERROR,
                    payload: errorMessage
                })
            });
    }
}
