import axios from 'axios';
import { config } from '../../config';
import { BakeryActionTypes } from './bakery.types';

export function GetBakeryData(id) {
    return (dispatch) => {
        dispatch({
            type: BakeryActionTypes.GET.REQUEST,
            payload: {}
        });

        axios.get(config.apiUrl + config.methods.bakeryBase + '/' + id)
            .then(function (response) {
                dispatch({
                    type: BakeryActionTypes.GET.SUCCESS,
                    payload: response.data
                })
            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";

                dispatch({
                    type: BakeryActionTypes.GET.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function ListBakeryData() {
    return (dispatch) => {
        dispatch({
            type: BakeryActionTypes.LIST.REQUEST,
            payload: {}
        });

        axios.get(config.apiUrl + config.methods.bakeryList)
            .then(function (response) {
                dispatch({
                    type: BakeryActionTypes.LIST.SUCCESS,
                    payload: response.data
                })
            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";
                dispatch({
                    type: BakeryActionTypes.LIST.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function DeleteBakery(id) {
    return (dispatch) => {
        dispatch({
            type: BakeryActionTypes.DELETE.REQUEST,
            payload: {}
        })

        axios.delete(config.apiUrl + config.methods.bakeryBase + '/' + id)
            .then(function (response) {
                dispatch(ListBakeryData())
                dispatch({
                    type: BakeryActionTypes.DELETE.SUCCESS,
                    payload: id
                })
            })
            .catch(function (error) {
                let errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка"

                dispatch({
                    type: BakeryActionTypes.DELETE.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function AddBakery(files, city, street, phone, hours, latitude, longitude, isActive, history) {
    return (dispatch) => {
        dispatch({
            type: BakeryActionTypes.ADD.REQUEST,
            payload: {}
        })

        let form = new FormData();
        if (files && files.length > 0){
            let images = [];
            for (let i = 0; i++; i < files.length){
                let image = {
                    'filename': files[i].name,
                    'image': files[i]
                }
                images.push(image);
            }
            form.append('images', JSON.stringify(images));
        }

        form.append('city', city);
        form.append('street', street);
        form.append('phone', phone);
        form.append('hours', hours);
        form.append('latitude', latitude);
        form.append('longitude', longitude);
        form.append('isActive', isActive);

        axios.post(config.apiUrl + config.methods.bakeryBase, form)
            .then(function (response) {
                history.push("/bakery")
                dispatch({
                    type: BakeryActionTypes.ADD.SUCCESS,
                    payload: {}
                });

            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";

                dispatch({
                    type: BakeryActionTypes.ADD.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function UpdateBakery(id, files, city, street, phone, hours, latitude, longitude, isActive, history) {
    return (dispatch) => {
        dispatch({
            type: BakeryActionTypes.UPDATE.REQUEST,
            payload: {}
        })

        let form = new FormData();
        if (files && files.length > 0){
            let images = [];
            for (let i = 0; i++; i < files.length){
                let image = {
                    'filename': files[i].name,
                    'image': files[i]
                }
                images.push(image);
            }
            form.append('images', JSON.stringify(images));
        }else{
            form.append('images', '');
        }

        form.append('city', city);
        form.append('street', street);
        form.append('phone', phone);
        form.append('hours', hours);
        form.append('latitude', latitude);
        form.append('longitude', longitude);
        form.append('isActive', isActive);

        axios.put(config.apiUrl + config.methods.bakeryBase + '/' + id, form)
            .then(function (response) {
                dispatch(ListBakeryData())
                history.push("/bakery")
                dispatch({
                    type: BakeryActionTypes.UPDATE.SUCCESS,
                    payload: {}
                });
            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";
                dispatch(ListBakeryData())
                history.push("/bakery")
                dispatch({
                    type: BakeryActionTypes.UPDATE.ERROR,
                    payload: errorMessage
                })
            });
    }
}
