import {Link} from "react-router-dom";
import {ReactComponent as EditIcon} from "../../assets/images/useful/svg/edit.svg";
import {ReactComponent as DeleteIcon} from "../../assets/images/useful/svg/cross.svg";
import ModalConfirmDelete from "../../components/modals/modalConfirmDelete";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {DeleteBakery} from "./bakery.actions";

export const BakeryTableRow = (props) => {
    return(
        <tr>
            <td>{props.city}</td>
            <td>{props.street}</td>
            <td>{props.phone}</td>
            <td>{props.hours}</td>
            <td>{props.isActive ? "Да" : "Нет"}</td>
            <td style={{textAlign: 'right'}}>
                <Link to={'/bakery/edit/' + props.id}><EditIcon/></Link>
                <DeleteIcon onClick={() => props.onDelete(props.id)}/>
            </td>
        </tr>
    );
}

export const BakeryTable = (props) => {
    const [selectedItem, setSelectedItem] = useState('');
    const [isDisplayModal, setIsDisplayModal] = useState(false);
    const dispatch = useDispatch();

    const handleOnDelete = (id) => {
        setSelectedItem(id);
        setIsDisplayModal(true);
    }

    const handleOnConfirmDelete = () => {
        dispatch(DeleteBakery(selectedItem));
        setSelectedItem('');
        setIsDisplayModal(false);
    }

    return(
        <>
            <table id="customers">
                <thead>
                <tr>
                    <th>Город</th>
                    <th>Адрес</th>
                    <th>Телефон</th>
                    <th>Рабочие часы</th>
                    <th>Активна</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    props.items && props.items.map((item, i) => {
                        return(
                            <BakeryTableRow key={i} {...item} onDelete={handleOnDelete}/>
                        );
                    })
                }
                </tbody>
            </table>
            <ModalConfirmDelete
                isDisplay={isDisplayModal}
                onClose={() => setIsDisplayModal(false)}
                onSubmit={handleOnConfirmDelete}
                buttonText="Удалить"
                title="Удалить пекарню?"
                text="Это действие нельзя будет отменить."
            />
        </>
    );
}
