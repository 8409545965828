import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {AddMember, GetMemberData, UpdateMember} from "./members.actions";
import {ValidateEmailField, ValidatePassword, ValidateTextField} from "../../utils/validation";
import InputPublic from "../../components/inputPublic";

const MembersEdit = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const member = useSelector(state => state.members);
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [emailError, setEmailError] = useState("");
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(true);
    const [nameError, setNameError] = useState("");
    const [isLoading, setIsLoading] = useState(false);


    useEffect(()=>{
        if (!isLoading){
            dispatch(GetMemberData(props.match.params.id));
            setIsLoading(true);
        }
        //return(() => resetForm())
    })

    useEffect(() => {
        if (member){
            console.log(member.data)
            setName(member.data.name);
            setEmail(member.data.email);
        }
    }, [member])

    const onHandleSubmit = () => {
        let validationResult = validateForm();
        if (validationResult === true) {
            dispatch(UpdateMember(props.match.params.id, name, email, history));
        }
    }

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value)
        switch (name){
            case "name":
                setName(value);
                const nameValidationResult = ValidateTextField(value);
                setNameValid(nameValidationResult.isValid);
                setNameError(nameValidationResult.message);
                break;
            case "email":
                setEmail(value);
                const emailValidationResult = ValidateEmailField(value);
                setEmailValid(emailValidationResult.isValid);
                setEmailError(emailValidationResult.message);
                break;
            default:
                break;
        }
    }

    function validateForm() {
        let emptyFields = false;
        if (!name){
            setNameValid(false);
            setNameError("Не указано имя");
            emptyFields = true;
        }

        if (!email){
            setEmailValid(false);
            setEmailError("Не указан email");
            emptyFields = true;
        }

        if (nameValid && emailValid && !emptyFields) {
            return true;
        }

        return false;
    }

    const resetForm = () => {
        console.log("re")
        setName("");
        setNameValid(true);
        setNameError("");
        setEmail("");
        setEmailValid(true);
        setEmailError("");
    }

    return(
        <div className="c-members">
            <h3>Администраторы / Редактировать</h3>
            <form id="c-entry__login" className="c-entry__edit-form">
                <div className="c-entry__line">
                    <InputPublic
                        title={'Фамилия Имя'}
                        name={'name'}
                        type={'text'}
                        placeholder={''}
                        error={!nameValid}
                        errorText={nameError}
                        data={name}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line">
                    <InputPublic
                        title={'Email'}
                        name={'email'}
                        type={'email'}
                        placeholder={''}
                        error={!emailValid}
                        errorText={emailError}
                        data={email}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line c-entry__line--mt">
                    <button type="button" className="e-btn e-btn--filled" onClick={onHandleSubmit}>Сохранить</button>
                </div>
            </form>
        </div>
    );
}

export default MembersEdit;
