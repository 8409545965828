import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {ProfileLogout} from "../pages/Auth/auth.actions";
import {ReactComponent as ExitIcon} from "../assets/images/useful/svg/logout.svg";

function Header(props) {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

   //startTime(setCurrentTime);
  if (document.querySelector('.js-open-mmenu')) {
    document.querySelector('.js-open-mmenu').onclick = function () {
      document.querySelector('.col-md-2').nextElementSibling.classList.add('page-scroll-off');
      document.querySelector('.mobile-burger').classList.add('show');
    };

    if (document.querySelector('.js-close-mmenu')) {
      document.querySelector('.js-close-mmenu').onclick = function () {
        document.querySelector('.mobile-burger').classList.remove('show');
        document.querySelector('.col-md-2').nextElementSibling.classList.remove('page-scroll-off');
      };
    }

    window.addEventListener('resize', function () {
      if (window.innerWidth > 991 && document.querySelector('.mobile-burger')) {
        document.querySelector('.mobile-burger').classList.remove('show');
      }
    });
  }

  return (
    <header className={`c-header ${props.location.pathname.includes('editor') && 'c-header__fixed'}`}>
      <div style={{width: '100%'}}>
        <div className='row align-items-center'>
          <div className='col-sm-2 col-5 align-items-center d-flex'>
            {props.button && (
              <button type='button' className='c-header__mmenu js-open-mmenu'>
                <span></span>
              </button>
            )}
            <div style={{ marginTop: '-10px;' }} className='c-header__logo'>
                <h3>Хлебная усадьба</h3>
            </div>
          </div>
          <div className='col-sm-8 col-5'>
            <div className='c-header__actions'>
                <span>{auth.data.userName}</span>
                <a href='#' className='c-menu__link' onClick={() => dispatch(ProfileLogout())}>
                    <ExitIcon className='c-menu__ico' style={{ width: '16px' }} />
                </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
