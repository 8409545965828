import React from "react";

const InputFile = (props) => {
    return(
        <div className="form-control" style={{display: 'flex'}}>
            {
                props.fileUrl && <img src={props.fileUrl} style={{width: '500px', height: '125px', flex:'0.2'}} alt="Нет изображения"/>
            }
            <span style={{flex: '0.6'}}>{props.fileName}</span>
            <div className='e-input-file' style={{flex:'0.2'}}>
                <label className={props.isSmall ? 'e-btn-sm e-btn--filled' : 'e-btn e-btn--filled'} >
                    Выбрать файл
                    <input
                        id='user-avatar'
                        type='file'
                        name='user-avatar'
                        accept={'.jpg, .jpeg, .png'}
                        onChange={props.onSelectFile}
                    />
                </label>
            </div>
        </div>
    );
}

export default InputFile;
