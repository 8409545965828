import {useDispatch} from "react-redux";
import {AddNotification} from "./notification.actions";
import React, {useState, useEffect} from "react";
import InputPublic from "../../components/inputPublic";
import {ValidateTextField} from "../../utils/validation";
import {useHistory} from "react-router-dom";
import InputDate from "../../components/inputDate";

const NotificationCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [description, setDescription] = useState('');
    const [descriptionValid, setDescriptionValid] = useState(true);
    const [descriptionError, setDescriptionError] = useState("");
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(true);
    const [nameError, setNameError] = useState("");
    const [date, setDate] = useState(new Date());
    const [dateValid, setDateValid] = useState(true);
    const [dateError, setDateError] = useState('');

    useEffect(() => {
        return(() => resetForm())
    }, [])

    const onHandleSubmit = () => {
        let validationResult = validateForm();
        if (validationResult === true) {
            dispatch(AddNotification(name, description, date, history));
        }
    }

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        switch (name){
            case "name":
                setName(value);
                const nameValidationResult = ValidateTextField(value);
                setNameValid(nameValidationResult.isValid);
                setNameError(nameValidationResult.message);
                break;
            case "description":
                setDescription(value);
                const descriptionValidationResult = ValidateTextField(value);
                setDescriptionValid(descriptionValidationResult.isValid);
                setDescriptionError(descriptionValidationResult.message);
                break;
            case "date":
                setDate(value);
                const dateValidationResult = ValidateTextField(value);
                setDateValid(dateValidationResult.isValid);
                setDateError(dateValidationResult.message);
                break;
            default:
                break;
        }
    }

    function validateForm() {
        let emptyFields = false;
        if (!name){
            setNameValid(false);
            setNameError("Не указан заголовок");
            emptyFields = true;
        }

        if (!description){
            setDescriptionValid(false);
            setDescriptionError("Не указан текст");
            emptyFields = true;
        }

        if (!date){
            setDateValid(false);
            setDateError("Не указана дата");
            emptyFields = true;
        }

        if (nameValid && descriptionValid && dateValid && !emptyFields) {
            return true;
        }

        return false;
    }

    const resetForm = () => {
        setName("");
        setNameValid(true);
        setNameError("");
        setDescription("");
        setDescriptionValid(true);
        setDescriptionError("");
        setDate(new Date());
        setDescriptionValid(true);
        setDescriptionError("");
    }

    return(
        <div className="c-members">
            <h3>Уведомления / Добавить</h3>
            <form id="c-entry__login" className="c-entry__edit-form">
                <div className="c-entry__line">
                    <InputPublic
                        title={'Заголовок'}
                        name={'name'}
                        type={'text'}
                        placeholder={''}
                        error={!nameValid}
                        errorText={nameError}
                        data={name}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line">
                    <InputPublic
                        title={'Текст'}
                        name={'description'}
                        type={'text'}
                        placeholder={''}
                        error={!descriptionValid}
                        errorText={descriptionError}
                        data={description}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line">
                    <InputDate title={'Дата и время отправки'} data={date} name={'date'}
                               error={!dateValid} errorText={dateError} type={'date'} onChange={setDate}/>
                </div>
                <div className="c-entry__line c-entry__line--mt">
                    <button type="button" className="e-btn e-btn--filled" onClick={onHandleSubmit}>Добавить</button>
                </div>
            </form>
        </div>
    );
}

export default NotificationCreate;
