export const BakeryActionTypes = {
    LIST: {
        REQUEST: "BAKERY_LIST_REQUEST",
        SUCCESS: "BAKERY_LIST_SUCCESS",
        ERROR: "BAKERY_LIST_ERROR"
    },
    GET: {
        REQUEST: "BAKERY_GET_REQUEST",
        SUCCESS: "BAKERY_GET_SUCCESS",
        ERROR: "BAKERY_GET_ERROR"
    },
    ADD: {
        REQUEST: "BAKERY_ADD_REQUEST",
        SUCCESS: "BAKERY_ADD_SUCCESS",
        ERROR: "BAKERY_ADD_ERROR"
    },
    DELETE: {
        REQUEST: "BAKERY_DELETE_REQUEST",
        SUCCESS: "BAKERY_DELETE_SUCCESS",
        ERROR: "BAKERY_DELETE_ERROR"
    },
    UPDATE: {
        REQUEST: "BAKERY_UPDATE_REQUEST",
        SUCCESS: "BAKERY_UPDATE_SUCCESS",
        ERROR: "BAKERY_UPDATE_ERROR"
    },
    CHANGE:{
        DATA_FIELD: "BAKERY_CHANGE_DATA_FIELD",
        RESET: "BAKERY_CHANGE_DATA_RESET"
    }
}
