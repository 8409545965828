import React, { useState, useEffect } from 'react';
import MaskedTextInput from "react-text-mask";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru"; // the locale you want
registerLocale("ru", ru); // register it with the name you want

function checkTime(i) {
    return (i < 10) ? "0" + i : i;
}

const InputDate = (props) => {
    const { title, data, placeholder, name, error, errorText, type, maxToday } = props;
    const [minDate, setMinDate] = useState("");
    const [maxDate, setMaxDate] = useState("");
    const [minTime, setMinTime] = useState(new Date(0, 0, 0, 0, 0, 0));
    const [selectedDate, setDate] = useState(new Date())
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }

        if (maxToday) {
            setMaxDate(today);
            setMinDate(new Date(yyyy - 18, mm - 1, dd))
        } else {
            setMaxDate(new Date(yyyy + 3, mm - 1, dd, 23, 59))
            setMinDate(today)
        }

        if (data && type === 'date') {
            let bd = new Date(data)
            let bdDays = bd.getDate();
            let bdMonth = bd.getMonth() + 1;
            if (bdDays < 10) {
                bdDays = '0' + bdDays
            }
            if (bdMonth < 10) {
                bdMonth = '0' + bdMonth
            }
            setDate(bd)
        } else if (data && type === 'time') {
            setDate(new Date(data))
        }
        //checkSelectedDate(selectedDate, today)
    }, [data])

    const handleOnChange = (e) => {
        const { onChange } = props;
        checkSelectedDate(e, new Date())
        if (onChange)
            onChange(e);
    }

    const handleOnChangeMasked = (e) => {
        console.log(e)
    }

    function checkSelectedDate(selected, today) {
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }

        let todayFormatted = dd + "/" + mm + "/" + yyyy;

        var sdd = selected.getDate();
        var smm = selected.getMonth() + 1; //January is 0!
        var syyyy = selected.getFullYear();
        if (sdd < 10) {
            sdd = '0' + sdd
        }
        if (smm < 10) {
            smm = '0' + smm
        }

        let selectedFormatted = sdd + "/" + smm + "/" + syyyy;

        if (todayFormatted === selectedFormatted) {
            setMinTime(new Date())
        } else {
            setMinTime(new Date(0, 0, 0, 0, 0, 0))
        }
        forceUpdate()

    }

    const RenderDatePicker = () => (
        <DatePicker
            id={name}
            name={name}
            dateFormat="dd.MM.yyyy"
            selected={data ? selectedDate : ''}
            onChange={date => props.onChange(date)}
            placeholderText="Не выбрано"
            locale="ru"
            className="e-input"
            minDate={minDate}
            maxDate={maxDate}
            showYearDropdown={true}
            customInput={
                <MaskedTextInput
                    className="e-input"
                    type="text"
                    placeholder="дд.мм.гггг"
                    mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
                />
            }
        />
    )

    const RenderTimePicker = (props) => (
        <DatePicker
            id={name}
            name={name}
            selected={selectedDate}
            onChange={date => handleOnChange(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Время"
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            locale="ru"
            //minTime={props.minTimeProps ? props.minTimeProps : minTime}
            //maxTime={maxDate}
            customInput={
                <MaskedTextInput
                    className="e-input"
                    type="text"
                    placeholder="чч:мм"
                    mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                />
            }
        />
    );

    return (
        <div className={`form-group e-input-autocomplete ${error ? 'error' : ''}`}>
            <label htmlFor={name} className="e-label">{title}</label>
            <div className="form-control">
                {type === "date" ? <RenderDatePicker /> : <RenderTimePicker minTimeProps={minTime} />}
                {error && <span className="form-group__error">{errorText}</span>}
            </div>
        </div>
    );
}

export default InputDate;
