import React from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import './assets/scss/all.scss';
import Header from './components/header';
import Auth from './pages/Auth/auth.component';
import Main from './pages/Main/main';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import ForgotPassword from './pages/Auth/auth.forgot';
import RegisterSuccess from './pages/Auth/auth.success';
import RecoverPassword from './pages/Auth/auth.recover';
import LoadingBar from 'react-redux-loading-bar';
import Cookies from 'cookies-js';
import axios from 'axios';
import { store } from 'react-notifications-component';
import ErrorNotification from './components/errorNotification';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisplayButton: false,
      isDisplaySearch: false,
      isDisplayNotifications: false
    };

    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        console.log('error', error);
        console.log('response', error.response);
        let errorText = 'Неизвестная ошибка';

        if ([400, 404, 405, 500].indexOf(error.response.status) !== -1) {
          if (error.response.data.error_description) {
            errorText = error.response.data.error_description;
          } else if (error.response.data.error) {
            errorText = error.response.data.error;
          } else if (error.response.data.content && error.response.data.content.errorMessage) {
            errorText = error.response.data.content.errorMessage;
          } else if (error.response.data.Message) {
            errorText = error.response.data.Message;
          } else if (error.response.data.message) {
            errorText = error.response.data.message;
          }

          if (!errorText) {
            errorText = 'Ошибка выполнения запроса';
          }

          store.addNotification({
            content: <ErrorNotification isError={true} title='Ошибка' errorText={errorText} />,
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            width: 420,
            dismiss: {
              duration: 3000
            }
          });
        } else if ([403].indexOf(error.response.status) !== -1) {
          window.location.href = '/notFound';
        }
      }
    );

    let accessToken = Cookies.get('access_token');

    if (props.location.pathname === '/') {
      window.location.href = '/advertising';
    }

    if (props.location.pathname === '/auth' && accessToken) {
      window.location.href = '/advertising';
    }
  }

  componentWillReceiveProps(nextProps, context) {
    let accessToken = Cookies.get('access_token');
    if (!accessToken) {
      this.setState({ isDisplaySearch: false });
      this.setState({ isDisplayButton: false });
      this.setState({ isDisplayNotifications: false });
    }
  }

  componentDidMount() {
    let accessToken = Cookies.get('access_token');
    if (accessToken) {
      this.setState({ isDisplaySearch: true });
      this.setState({ isDisplayNotifications: true });
      this.setState({ isDisplayButton: true });
    }
  }

  render() {
    return (
      <div className='l-wrapper'>
        <LoadingBar style={{ backgroundColor: '#B0BAC4', height: '3px' }} />
        <ReactNotification />
          {
              this.state.isDisplaySearch &&
              <Header
                  calendar={true}
                  search={this.state.isDisplaySearch}
                  button={this.state.isDisplayButton}
                  notification={this.state.isDisplayNotifications}
                  location={this.props.location}
              />
          }

        <main style={{height: '100vh'}}>
          <div style={{height: '100%'}}>
            <Router>
              <Switch>
                <Route exact path='/forgot' component={ForgotPassword} />
                <Route exact path='/auth' component={Auth} />
                <Route exact path='/auth/confirm' component={RegisterSuccess} />
                <Route exact path='/auth/recover/:id?/:code?' component={RecoverPassword} />
                <Route path='/' render={props => <Main {...props} />} />
              </Switch>
            </Router>
          </div>
        </main>
      </div>
    );
  }
}

export default withRouter(App);
