export const AdvertisingActionTypes = {
    LIST: {
        REQUEST: "ADVERTISING_LIST_REQUEST",
        SUCCESS: "ADVERTISING_LIST_SUCCESS",
        ERROR: "ADVERTISING_LIST_ERROR"
    },
    GET: {
        REQUEST: "ADVERTISING_GET_REQUEST",
        SUCCESS: "ADVERTISING_GET_SUCCESS",
        ERROR: "ADVERTISING_GET_ERROR"
    },
    ADD: {
        REQUEST: "ADVERTISING_ADD_REQUEST",
        SUCCESS: "ADVERTISING_ADD_SUCCESS",
        ERROR: "ADVERTISING_ADD_ERROR"
    },
    DELETE: {
        REQUEST: "ADVERTISING_DELETE_REQUEST",
        SUCCESS: "ADVERTISING_DELETE_SUCCESS",
        ERROR: "ADVERTISING_DELETE_ERROR"
    },
    UPDATE: {
        REQUEST: "ADVERTISING_UPDATE_REQUEST",
        SUCCESS: "ADVERTISING_UPDATE_SUCCESS",
        ERROR: "ADVERTISING_UPDATE_ERROR"
    },
    CHANGE:{
        DATA_FIELD: "ADVERTISING_CHANGE_DATA_FIELD",
        RESET: "ADVERTISING_CHANGE_DATA_RESET"
    }
}
