export const MembersActionTypes = {
    LIST: {
        REQUEST: "MEMBERS_LIST_REQUEST",
        SUCCESS: "MEMBERS_LIST_SUCCESS",
        ERROR: "MEMBERS_LIST_ERROR"
    },
    GET: {
        REQUEST: "MEMBERS_GET_REQUEST",
        SUCCESS: "MEMBERS_GET_SUCCESS",
        ERROR: "MEMBERS_GET_ERROR"
    },
    ADD: {
        REQUEST: "MEMBERS_ADD_REQUEST",
        SUCCESS: "MEMBERS_ADD_SUCCESS",
        ERROR: "MEMBERS_ADD_ERROR"
    },
    DELETE: {
        REQUEST: "MEMBERS_DELETE_REQUEST",
        SUCCESS: "MEMBERS_DELETE_SUCCESS",
        ERROR: "MEMBERS_DELETE_ERROR"
    },
    UPDATE: {
        REQUEST: "MEMBERS_UPDATE_REQUEST",
        SUCCESS: "MEMBERS_UPDATE_SUCCESS",
        ERROR: "MEMBERS_UPDATE_ERROR"
    },
    CHANGE:{
        DATA_FIELD: "MEMBERS_CHANGE_DATA_FIELD",
        RESET: "MEMBERS_CHANGE_DATA_RESET"
    }
}
