import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SymbolDefs from './../assets/images/useful/svg/theme/symbol-defs.svg';
import { ReactComponent as ExitIcon } from './../assets/images/useful/svg/exit.svg';
import { ReactComponent as SmileIcon } from './../assets/images/useful/svg/bad-smile.svg';
import { ReactComponent as LockIcon } from './../assets/images/useful/svg/lock-menu.svg';
import { ReactComponent as SearchIcon } from './../assets/images/useful/svg/search.svg';
import { ReactComponent as StarIcon } from './../assets/images/useful/svg/star.svg';
import { ReactComponent as MessageIcon } from './../assets/images/useful/svg/message.svg';
import { ProfileLogout } from './../pages/Auth/auth.actions';

const AdminMenu = props => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const reports = useSelector(state => state.reports);
  const banned = useSelector(state => state.banned);
  const [reportsCount, setReportsCount] = useState(null);

  const filterReports = (reports, banned, type) => {
    if (type === 'community') return reports.filter(({ community }) => !banned.some(({ id }) => community.id !== id));
    else if (type === 'event') return reports.filter(({ event }) => !banned.some(({ id }) => event.id !== id));
    else if (type === 'notification') return reports.filter(({ notification }) => !banned.some(({ id }) => notification.id !== id));
    else return reports.filter(({ news }) => !banned.some(({ id }) => news.id !== id));
  }

  useEffect(() => {
    const { news, notifications, communities, events } = reports;
    const { news: bannedNews, notifications: bannedNotifications, communities: bannedCommunities, events: bannedEvents } = banned;
    const newsReports = filterReports(news, bannedNews, 'news');
    const notificationReports = filterReports(notifications, bannedNotifications, 'notification');
    const eventReports = filterReports(events, bannedEvents, 'event');
    const communityReports = filterReports(communities, bannedCommunities, 'community');
    setReportsCount(newsReports.length + notificationReports.length + eventReports.length + communityReports.length);
  }, [reports, banned]);
  useEffect(() => {}, [auth]);

  return (
    <div className='c-menu'>
      <h3 className='e-title--sm'>Меню</h3>
      <div className='c-menu__wrapper'>
        <ul className='h-reset-list c-menu__list'>
          <li className='c-menu__item'>
            <a href='/admin/reports' className='c-menu__link'>
              <SmileIcon className='c-menu__ico' />
              <span className='c-menu__txt'>Жалобы</span>
              <span className='c-menu__notify' style={{ marginLeft: '28px' }}>
                {!reportsCount ? '' : reportsCount}
              </span>
            </a>
          </li>
          {(auth.role.isSuperAdminRole || auth.role.isAdminRole || auth.adminPermissions.isAllowedBlockContent) && (
            <li className='c-menu__item'>
              <a href='/admin/banned' className='c-menu__link'>
                <LockIcon className='c-menu__ico' />
                <span className='c-menu__txt'>Заблокированное</span>
              </a>
            </li>
          )}
          {auth.role.isSuperAdminRole && (
            <li className='c-menu__item'>
              <a href='/admin/accessrights' className='c-menu__link'>
                <svg width='1em' height='1em' className='icon icon-community c-menu__ico'>
                  <use xlinkHref={`${SymbolDefs}#icon-community`}></use>
                </svg>
                <span className='c-menu__txt'>Права доступа</span>
              </a>
            </li>
          )}
          {(auth.role.isSuperAdminRole || auth.role.isAdminRole || auth.adminPermissions.isAllowedEditStructure) && (
            <li className='c-menu__item'>
              <a href='/admin/structure' className='c-menu__link'>
                <svg width='1em' height='1em' className='icon icon-stripe c-menu__ico'>
                  <use xlinkHref={`${SymbolDefs}#icon-stripe`}></use>
                </svg>
                <span className='c-menu__txt'>Структура РОСТ</span>
              </a>
            </li>
          )}
          {auth.role.isSuperAdminRole && (
            <li className='c-menu__item'>
              <a href='/admin/message' className='c-menu__link'>
                <MessageIcon className='c-menu__ico' />
                <span className='c-menu__txt'>Сообщения</span>
              </a>
            </li>
          )}
          {auth.role.isSuperAdminRole && (
            <li className='c-menu__item'>
              <a href='/admin/counter' className='c-menu__link'>
                <StarIcon className='c-menu__ico' />
                <span className='c-menu__txt'>Счетчики</span>
              </a>
            </li>
          )}
          {auth.isAllowedSearchTalents && (
            <li className='c-menu__item'>
              <a href='/admin/talents' className='c-menu__link'>
                <SearchIcon className='c-menu__ico' />
                <span className='c-menu__txt'>Поиск талантов</span>
              </a>
            </li>
          )}
        </ul>
        <ul className='h-reset-list c-menu__list'>
          <li className='c-menu__item'>
            <a href='#' className='c-menu__link' onClick={() => dispatch(ProfileLogout())}>
              <ExitIcon className='c-menu__ico' />
              <span className='c-menu__txt'>Выход</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminMenu;
