export const config = {
    apiUrl: 'https://api.beta.hlebnaya-usadba.notissimus.com',
    //apiUrl: 'https://localhost:7030',
  methods: {
      login: '/account/login',
      register: '/account/register',
      accountRefresh: '/account/refresh',
      registerAdmin: '/account/register-admin',
      changePassword: '/account/Password/Change',
      forgotPassword: '/account/Password/Forgot',
      resetPassword: '/account/Password/Reset',
      confirmEmail: '/account/confirm',
      adminGet: '/account/get',
      adminList: '/account/list',
      adminAdd: '/account/register',
      adminUpdate: '/account/update',
      adminDelete: '/account/delete',
      advertisingList: '/advertising/list/all',
      advertisingBase: '/advertising',
      bakeryList: '/bakery/list/all',
      bakeryBase: '/bakery',
      notificationList: '/notification/list/all',
      notificationBase: '/notification',
      categoryList: '/categoryImage',
      categoryUpdate: '/categoryImage',
  }
};
