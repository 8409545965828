import React from 'react'
import Menu from "../components/menu";

export default ({ children }) => (
    <div className="row" style={{height: '100%', overflowY: 'scroll'}}>
        <div className="col-md-2 c-menu__body">
            <Menu />
        </div>
        <div className="col-md-6 col-sm-10">
            <div className="main-content">
                {children}
            </div>
        </div>
    </div>
)
