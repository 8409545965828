import React from 'react';

const TextArea = (props) => {
    const { title, data, name, onChange, error, errorText } = props;

    return (
        <div className={`form-group h100 e-input-autocomplete ${error ? 'error' : ''}`}>
            <label className="e-label">{title}</label>
            <div className="form-control">
                <textarea id={name} name={name} value={data} className="e-textarea" onChange={(e) => onChange(e)} 
                    style={props.isLarge ? {minHeight: '150px'} : {}}></textarea>
                {error && <span className="form-group__error">{errorText}</span>}
            </div>
        </div>
    );
}

export default TextArea;