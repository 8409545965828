import React from 'react'
import { Route } from 'react-router'
const AppRoute = ({ component: Component, layout: Layout, connection: connection,  ...rest }) => (
    <Route
        {...rest}        
        render={props => (
            <Layout>
                <Component {...props} connection={connection ?? null} />
            </Layout>
        )}
    />
)

export default AppRoute;