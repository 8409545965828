import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Menu from './menu';
import AdminMenu from './adminMenu';
import WorkerMenu from './workerMenu';

const TopMenu = props => {
  const [selected, setSelected] = useState('Личный');
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    if (
      window.location.pathname.indexOf('structure') !== -1 ||
      window.location.pathname.indexOf('reports') !== -1 ||
      window.location.pathname.indexOf('banned') !== -1 ||
      window.location.pathname.indexOf('accessrights') !== -1 ||
      window.location.pathname.indexOf('/admin/') !== -1
    ) {
      setSelected('Админ');
    } else if (window.location.pathname.indexOf('worker') !== -1) {
      setSelected('Рабочий');
    } else {
      setSelected('Личный');
    }
    // console.log(auth)
  });

  return (
    <div className='left-side'>
      <div className='mobile-burger'>
        <div className='mobile-burger__head'>
          <button type='button' className='mobile-burger__btn js-close-mmenu'>
            <span></span>
          </button>
        </div>
        <div className='mobile-burger__content'>
          <div className='c-cabinet'>
            <h3 className='e-title--sm'>Кабинет</h3>
            <button type='button' className='c-cabinet__btn'>
              {selected}
              <div className='c-cabinet__list'>
                <div className='c-cabinet__items'>
                  <a href='/profile' className='c-cabinet__item' onClick={() => setSelected('Личный')}>
                    Личный
                  </a>
                  {(auth.isEmployeeRole || auth.isSelfEmployeeRole) && (
                    <a href='/worker/communities' className='c-cabinet__item' onClick={() => setSelected('Рабочий')}>
                      Рабочий
                    </a>
                  )}
                  {(auth.isSuperAdminRole || auth.isAdminRole) && (
                    <a href='/admin/reports' className='c-cabinet__item' onClick={() => setSelected('Админ')}>
                      Админ
                    </a>
                  )}
                </div>
              </div>
            </button>
          </div>
          {selected === 'Личный' ? (
            <Menu />
          ) : selected === 'Рабочий' ? (
            <WorkerMenu
              isInstitutionDisplay={(auth.isEmployeeRole || auth.isSelfEmployeeRole) && auth.employeeRoleType}
              isSearchTalentsAllowed={auth.isAllowedSearchTalents}
            />
          ) : (
            <AdminMenu />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
