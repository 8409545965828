export const CategoriesTypes = {
    LIST: {
        REQUEST: "CATEGORY_LIST_REQUEST",
        SUCCESS: "CATEGORY_LIST_SUCCESS",
        ERROR: "CATEGORY_LIST_ERROR"
    },
    UPDATE: {
        REQUEST: "CATEGORY_UPDATE_REQUEST",
        SUCCESS: "CATEGORY_UPDATE_SUCCESS",
        ERROR: "CATEGORY_UPDATE_ERROR"
    },
}
