import axios from 'axios';
import { config } from '../../config';
import { NotificationActionTypes } from './notification.types';
import {getDateFormatted} from "../../utils/dateHelpers";

export function GetNotificationData(id) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.GET.REQUEST,
            payload: {}
        });

        axios.get(config.apiUrl + config.methods.notificationBase + '/' + id)
            .then(function (response) {
                dispatch({
                    type: NotificationActionTypes.GET.SUCCESS,
                    payload: response.data
                })
            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";

                dispatch({
                    type: NotificationActionTypes.GET.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function ListNotificationData() {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.LIST.REQUEST,
            payload: {}
        });

        axios.get(config.apiUrl + config.methods.notificationList)
            .then(function (response) {
                dispatch({
                    type: NotificationActionTypes.LIST.SUCCESS,
                    payload: response.data
                })
            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";
                dispatch({
                    type: NotificationActionTypes.LIST.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function DeleteNotification(id) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.DELETE.REQUEST,
            payload: {}
        })

        axios.delete(config.apiUrl + config.methods.notificationBase + '/' + id)
            .then(function (response) {
                dispatch(ListNotificationData())
                dispatch({
                    type: NotificationActionTypes.DELETE.SUCCESS,
                    payload: id
                })
            })
            .catch(function (error) {
                let errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка"

                dispatch({
                    type: NotificationActionTypes.DELETE.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function AddNotification(name, description, date, history) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.ADD.REQUEST,
            payload: {}
        })

        let data = {
            Name: name,
            Description: description,
            Date: getDateFormatted(date)
        }

        axios.post(config.apiUrl + config.methods.notificationBase, data)
            .then(function (response) {
                dispatch(ListNotificationData())
                history.push("/notification")
                dispatch({
                    type: NotificationActionTypes.ADD.SUCCESS,
                    payload: {}
                });

            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";

                dispatch({
                    type: NotificationActionTypes.ADD.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function UpdateNotification(id, name, description, date, history) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.UPDATE.REQUEST,
            payload: {}
        })

        let data = {
            Name: name,
            Description: description,
            Date: getDateFormatted(date)
        }

        axios.put(config.apiUrl + config.methods.notificationBase + '/' + id, data)
            .then(function (response) {
                dispatch(ListNotificationData())
                history.push("/notification")
                dispatch({
                    type: NotificationActionTypes.UPDATE.SUCCESS,
                    payload: {}
                });
            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";

                dispatch({
                    type: NotificationActionTypes.UPDATE.ERROR,
                    payload: errorMessage
                })
            });
    }
}
