import axios from 'axios';
import { config } from '../../config';
import { MembersActionTypes } from './members.types';

export function GetMemberData(id) {
    return (dispatch) => {
        dispatch({
            type: MembersActionTypes.GET.REQUEST,
            payload: {}
        });

        axios.get(config.apiUrl + config.methods.adminGet + '/' + id)
            .then(function (response) {
                dispatch({
                    type: MembersActionTypes.GET.SUCCESS,
                    payload: response.data
                })
            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";

                dispatch({
                    type: MembersActionTypes.GET.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function ListMemberData() {
    return (dispatch) => {
        dispatch({
            type: MembersActionTypes.LIST.REQUEST,
            payload: {}
        });

        axios.get(config.apiUrl + config.methods.adminList)
            .then(function (response) {
                dispatch({
                    type: MembersActionTypes.LIST.SUCCESS,
                    payload: response.data
                })
            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";
                dispatch({
                    type: MembersActionTypes.LIST.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function DeleteMember(id) {
    return (dispatch) => {
        dispatch({
            type: MembersActionTypes.DELETE.REQUEST,
            payload: {}
        })

        axios.delete(config.apiUrl + config.methods.adminDelete + '/' + id)
            .then(function (response) {
                dispatch(ListMemberData())
                dispatch({
                    type: MembersActionTypes.DELETE.SUCCESS,
                    payload: id
                })
            })
            .catch(function (error) {
                let errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка"

                dispatch({
                    type: MembersActionTypes.DELETE.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function AddMember(name, email, password, confirmPassword, history) {
    return (dispatch) => {
        dispatch({
            type: MembersActionTypes.ADD.REQUEST,
            payload: {}
        })

        let data = {
            Name: name,
            Email: email,
            Password: password,
            ConfirmPassword: confirmPassword,
        }

        axios.post(config.apiUrl + config.methods.adminAdd, data)
            .then(function (response) {
                dispatch(ListMemberData())
                history.push("/members")
                dispatch({
                    type: MembersActionTypes.ADD.SUCCESS,
                    payload: {}
                });

            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";

                dispatch({
                    type: MembersActionTypes.ADD.ERROR,
                    payload: errorMessage
                })
            });
    }
}

export function UpdateMember(id, name, email, history) {
    return (dispatch) => {
        dispatch({
            type: MembersActionTypes.UPDATE.REQUEST,
            payload: {}
        })

        let data = {
            Name: name,
            Email: email
        }

        axios.put(config.apiUrl + config.methods.adminUpdate + '/' + id, data)
            .then(function (response) {
                dispatch(ListMemberData())
                history.push("/members")
                dispatch({
                    type: MembersActionTypes.UPDATE.SUCCESS,
                    payload: {}
                });
            })
            .catch(function (error) {
                const errorMessage = error.response ? error.response.data.message : "Внутреняя ошибка";

                dispatch({
                    type: MembersActionTypes.UPDATE.ERROR,
                    payload: errorMessage
                })
            });
    }
}
