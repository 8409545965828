import { combineReducers } from 'redux';
import authReducer from './../pages/Auth/auth.reducer';
import advertisingReducer from "../pages/Advertising/advertising.reducer";
import feedbackReducer from "../pages/Feedback/feedback.reducer";
import membersReducer from "../pages/Members/members.reducer";
import newsReducer from "../pages/News/news.reducer";
import dictionaryReducer from "../pages/Dictionary/dictionary.reducer";
import bakeryReducer from "../pages/Bakery/bakery.reducer";
import { routerReducer } from 'react-router-redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import notificationReducer from "../pages/Notification/notification.reducer";
import categoriesReducer from "../pages/Categories/categories.reducer";

const rootReducer = combineReducers({
  loadingBar: loadingBarReducer,
  auth: authReducer,
    advertising: advertisingReducer,
    members: membersReducer,
    dictionaries: dictionaryReducer,
    news: newsReducer,
    feedbacks: feedbackReducer,
  bakeries: bakeryReducer,
  notifications: notificationReducer,
  categories: categoriesReducer,
  routing: routerReducer
});

export default rootReducer;
