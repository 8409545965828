import Cookies from 'cookies-js';
import axios from 'axios';
import {
    AUTH_LOGIN_REQUEST, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_ERROR, AUTH_INIT, AUTH_LOGOUT, AUTH_PROFILE_CHANGE,
    AUTH_REGISTER_REQUEST, AUTH_REGISTER_SUCCESS, AUTH_REGISTER_ERROR,
    AUTH_FORGOT_PASSWORD_REQUEST, AUTH_FORGOT_PASSWORD_SUCCESS, AUTH_FORGOT_PASSWORD_ERROR,
    AUTH_RESET_PASSWORD_REQUEST, AUTH_RESET_PASSWORD_SUCCESS, AUTH_RESET_PASSWORD_ERROR,
    AUTH_CHANGE_PASSWORD_REQUEST, AUTH_CHANGE_PASSWORD_SUCCESS, AUTH_CHANGE_PASSWORD_ERROR, AUTH_CHANGE_ROLE
} from './auth.types';

const INITIAL_STATE = {
    user: null,
    fetching: false,
    error: false,
    errorText: "",
    accessToken: "",
    userName: "",
    backUrl: "/",
    recoverEmailSent: false,
    confirmEmailSent: false,
    isAdminRole: false,
    data: {
        userId: "",
        userName: ""
    },
    role: {
        isAdminRole: false
    },
    roles: []
};

const access_token = "access_token";
const isAdminRole = "isAdminRole";
const userId = "userId";
const userName = "userName";

const reducer = (state = INITIAL_STATE, action) => {
    let response = action.payload;
    let cookieUserId = Cookies.get(userId);
    let accessToken = Cookies.get(access_token);
    let cookieAdminRole = Cookies.get(isAdminRole);
    let cookieUserName = Cookies.get(userName);

    switch (action.type) {
        case AUTH_LOGIN_REQUEST:
            return {
                ...state,
                fetching: true
            };
        case AUTH_LOGIN_SUCCESS:
            Cookies.set(access_token, response.token, { path: "/", expires: response.expiration });
            Cookies.set(userId, response.userId, { path: "/", expires: response.expiration });
            Cookies.set(userName, response.userName, { path: "/", expires: response.expiration });
            Cookies.set(isAdminRole, response.isAdminRole, { path: "/", expires: response.expiration });
            return {
                ...state,
                fetching: false,
                accessToken: response.token,
                isAdminRole: response.isAdminRole,
                data: {
                    userId: response.userId,
                    userName: response.userName
                },
                role: {
                    isAdminRole: response.isAdminRole,
                }
            };
        case AUTH_LOGIN_ERROR:
            return {
                ...state,
                fetching: false,
                error: true,
                errorText: action.payload
            };
        case AUTH_REGISTER_REQUEST:
            return {
                ...state,
                fetching: true
            };
        case AUTH_REGISTER_SUCCESS:
            return {
                ...state,
                fetching: false,
                confirmEmailSent: true
            };
        case AUTH_REGISTER_ERROR:
            return {
                ...state,
                fetching: false,
                error: true,
                errorText: action.payload
            };
        case AUTH_INIT:
            axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
            let roles = INITIAL_STATE.roles;
            if (cookieAdminRole === "true" && roles.indexOf("Admin") === -1) {
                roles.push("Admin")
            }
            return {
                ...state,
                isAdminRole: cookieAdminRole === "true",
                roles: roles,
                isAuth: true,
                data: {
                    userId: cookieUserId,
                    userName: cookieUserName
                },
                role: {
                    isAdminRole: cookieAdminRole === "true",
                }
            };
        case AUTH_LOGOUT:
            Cookies.set(access_token);
            Cookies.set(isAdminRole);
            return {
                ...state,
                backUrl: action.payload.backUrl ? action.payload.backUrl : state.backUrl
            };
        case AUTH_PROFILE_CHANGE:
            return { ...state, [action.payload.name]: action.payload.value }
        case AUTH_CHANGE_ROLE:

        case AUTH_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                recoverEmailSent: true
            }
        case AUTH_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                recoverEmailSent: true
            }
        default: return state;
    }
}

export default reducer;
