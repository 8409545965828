import React from 'react';

const NotFound = () => (
    <div className="c-my-page">
        <div className="c-my-page__wrapper">
            <div className="c-my-page__user c-my-page-user">
                <h2 className="e-title--md">Недостаточно прав для просмотра</h2>
            </div>
        </div>
    </div>
);

export default NotFound;