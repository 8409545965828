export const FeedbackActionTypes = {
    GET: {
        REQUEST: "FEEDBACK_GET_REQUEST",
        SUCCESS: "FEEDBACK_GET_SUCCESS",
        ERROR: "FEEDBACK_GET_ERROR"
    },
    APPROVE: {
        REQUEST: "FEEDBACK_APPROVE_REQUEST",
        SUCCESS: "FEEDBACK_APPROVE_SUCCESS",
        ERROR: "FEEDBACK_APPROVE_ERROR"
    },
    DECLINE: {
        REQUEST: "FEEDBACK_DECLINE_REQUEST",
        SUCCESS: "FEEDBACK_DECLINE_SUCCESS",
        ERROR: "FEEDBACK_DECLINE_ERROR"
    },
    CHANGE:{
        DATA_FIELD: "FEEDBACK_CHANGE_DATA_FIELD",
        RESET: "FEEDBACK_CHANGE_DATA_RESET"
    },
    MODERATE_LIST: {
        REQUEST: "MODERATE_SLIDER_LIST_REQUEST",
        SUCCESS: "MODERATE_SLIDER_LIST_SUCCESS",
        ERROR: "MODERATE_SLIDER_LIST_ERROR"
    },
    APPROVED_LIST: {
        REQUEST: "APPROVED_LIST_REQUEST",
        SUCCESS: "APPROVED_LIST_SUCCESS",
        ERROR: "APPROVED_LIST_ERROR"
    },
    DECLINED_LIST: {
        REQUEST: "DECLINED_REQUEST",
        SUCCESS: "DECLINED_SUCCESS",
        ERROR: "DECLINED_ERROR"
    }
}
