export const ValidatePassword = (value) => {
    const regexSmall = new RegExp("^(?=.*[a-z])");
    const regexCapital = new RegExp("^(?=.*[A-Z])");
    const regexNumber = new RegExp("^(?=.*[0-9])");
    const regexSpecial = new RegExp("^(?=.*[!@#\$%\^&\*\(\)])");
    const regexLenght = new RegExp("^(?=.{6,})");

    let result = {
        isValid: false,
        message: ''
    };

    if (!value || value == '') {
        result.isValid = false;
        result.message = result.isValid ? '' : 'Не указан пароль';
    } else {
        if (!value.match(regexCapital)) {
            result.isValid = false;
            result.message = 'Должен содержать заглавную букву';
        } else if (!value.match(regexSmall)) {
            result.isValid = false;
            result.message = 'Должен содержать прописную букву';
        } else if (!value.match(regexSpecial)) {
            result.isValid = false;
            result.message = 'Должен содержать символ !@#$%^&*()';
        } else if (!value.match(regexNumber)) {
            result.isValid = false;
            result.message = 'Должен содержать цифры';
        } else if (!value.match(regexLenght)) {
            result.isValid = false;
            result.message = 'Должен быть не менее 6ти символов';
        } else {
            result.isValid = true;
            result.message = '';
        }
    }

    return result;
};

export const ValidateTextField = (value) => {
    let result = {
        isValid: false,
        message: ''
    };

    if (!value || value == '') {
        result.message = 'Не указано значение';
    } else {
        result.isValid = true;
        result.message = '';
    }

    return result;
};

export const ValidateTextWithSymbolsField = (value) => {
    const cyrillicRegexp = new RegExp("^[а-яА-ЯёЁa-zA-Z0-9 -№.,\"]+$");
    let result = {
        isValid: false,
        message: ''
    };

    if (!value || value == '') {
        result.message = 'Не указано значение';
    } else if (!value.match(cyrillicRegexp)) {
        result.message = 'Допускаются русские буквы и цифры';
    } else {
        result.isValid = true;
        result.message = '';
    }

    return result;
};

export const ValidateEmailField = (value) => {
    var regEx = new RegExp("^([\a-z0-9.%+-_]+)@([\a-z0-9-_]+\.)+([\a-z0-9-_]{2,4})$", "iu");
    let indexDog = value ? value.indexOf('@') : -1;
    let indexDot = value ? value.lastIndexOf('.') : -1;
    let registerEmailValid = value ? (value.match(regEx) && (indexDog < indexDot)) : false;
    let result = {
        isValid: registerEmailValid,
        message: ''
    };

    if (!value) {
        result.message = 'Введите email';
    } else {
        result.message = registerEmailValid ? '' : 'Не верный формат';
    }

    return result;
};

export const ValidatePhoneField = (value) => {
    let registerPhoneValid = value && value !== '' && value.length === 18 && value.indexOf('_') === -1;
    let result = {
        isValid: registerPhoneValid,
        message: ''
    };
    if (!value) {
        result.message = 'Введите телефон';
    } else {
        result.message = registerPhoneValid ? '' : 'Не верный формат';
    }

    return result;
};

export const ValidateMaxLenght = (value, length, required = true, errorText = '') => {
    let result = {
        isValid: true,
        message: ''
    };

    if (!value && !required) {
        return result;
    }

    if ((!value || value === "") && required) {
        result.isValid = false;
        result.message = errorText ? errorText : `Не указано значение`
        return result;
    }

    if (value.length > length) {
        result.isValid = false;
        result.message = `Не более ${length} символов`
    }

    return result;
};

export const ValidatePersonalId = (value) => {
    let result = {
        isValid: true,
        message: ''
    };

    if (value.length < 12){
        result.isValid = false;
        result.message = 'ID должен быть длиной 12 символов';
    }

    if (!value.match(/^[0-9]*$/)) {
        result.isValid = false;
        result.message = 'ID должен содержать только цифры';
    }

    return result;
};
