import {useDispatch} from "react-redux";
import {AddMember} from "./members.actions";
import {useState, useEffect} from "react";
import InputPublic from "../../components/inputPublic";
import {ValidatePassword, ValidateTextField,ValidateEmailField} from "../../utils/validation";
import {useHistory} from "react-router-dom";

const MembersCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [emailError, setEmailError] = useState("");
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(true);
    const [nameError, setNameError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState(true);
    const [passwordError, setPasswordError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    useEffect(() => {
        return(() => resetForm())
    }, [])

    const onHandleSubmit = () => {
        let validationResult = validateForm();
        if (validationResult === true) {
            dispatch(AddMember(name, email, password, confirmPassword, history));
        }
    }

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        switch (name){
            case "name":
                setName(value);
                const nameValidationResult = ValidateTextField(value);
                setNameValid(nameValidationResult.isValid);
                setNameError(nameValidationResult.message);
                break;
            case "email":
                setEmail(value);
                const emailValidationResult = ValidateEmailField(value);
                setEmailValid(emailValidationResult.isValid);
                setEmailError(emailValidationResult.message);
                break;
            case "password":
                setPassword(value);
                const passwordValidationResult = ValidatePassword(value);
                setPasswordValid(passwordValidationResult.isValid);
                setPasswordError(passwordValidationResult.message);
                break;
            case "confirmPassword":
                setConfirmPassword(value);
                const passwordConfirmValidationResult = ValidatePassword(value);
                setConfirmPasswordValid(passwordConfirmValidationResult.isValid);
                setConfirmPasswordError(passwordConfirmValidationResult.message);
                break;
            default:
                break;
        }
    }

    function validateForm() {
        let emptyFields = false;
        if (!name){
            setNameValid(false);
            setNameError("Не указано имя");
            emptyFields = true;
        }

        if (!email){
            setEmailValid(false);
            setEmailError("Не указан email");
            emptyFields = true;
        }

        if (!password) {
            setPasswordValid(false);
            setPasswordError("Не указан пароль");
            emptyFields = true;
        }
        if (!confirmPassword) {
            setConfirmPasswordValid(false);
            setConfirmPasswordError("Не указан пароль");
            emptyFields = true;
        }

        if (nameValid && emailValid && passwordValid && confirmPasswordValid && !emptyFields && comparePasswords()) {
            return true;
        }

        return false;
    }

    const comparePasswords = () => {
        let passwordsEqual = false;
        if (password !== confirmPassword) {
            setPasswordValid(false);
            setPasswordError('Пароли не совпадают');
            setConfirmPasswordValid(false);
            setConfirmPasswordError('Пароли не совпадают');
        } else {
            setPasswordValid(true);
            setPasswordError('');
            setConfirmPasswordValid(true);
            setConfirmPasswordError('');
            passwordsEqual = true;
        }

        return passwordsEqual;
    }

    const resetForm = () => {
        setName("");
        setNameValid(true);
        setNameError("");
        setEmail("");
        setEmailValid(true);
        setEmailError("");
        setPassword("");
        setPasswordValid(true);
        setPasswordError("");
        setConfirmPassword("");
        setConfirmPasswordValid(true);
        setConfirmPasswordError("");
    }

    return(
        <div className="c-members">
            <h3>Администраторы / Добавить</h3>
            <form id="c-entry__login" className="c-entry__edit-form">
                <div className="c-entry__line">
                    <InputPublic
                        title={'Фамилия Имя'}
                        name={'name'}
                        type={'text'}
                        placeholder={''}
                        error={!nameValid}
                        errorText={nameError}
                        data={name}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line">
                    <InputPublic
                        title={'Email'}
                        name={'email'}
                        type={'email'}
                        placeholder={''}
                        error={!emailValid}
                        errorText={emailError}
                        data={email}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line">
                    <InputPublic
                        title={'Пароль'}
                        name={'password'}
                        type={'text'}
                        placeholder={''}
                        error={!passwordValid}
                        errorText={passwordError}
                        data={password}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line">
                    <InputPublic
                        title={'Подтвердите пароль'}
                        name={'confirmPassword'}
                        type={'text'}
                        placeholder={''}
                        error={!confirmPasswordValid}
                        errorText={confirmPasswordError}
                        data={confirmPassword}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line c-entry__line--mt">
                    <button type="button" className="e-btn e-btn--filled" onClick={onHandleSubmit}>Добавить</button>
                </div>
            </form>
        </div>
    );
}

export default MembersCreate;
