import { AdvertisingActionTypes } from './advertising.types'

const INITIAL_STATE = {
    data: {},
    list:[],
    error: false,
    errorText: "",
    fetching: false
}

const advertisingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AdvertisingActionTypes.GET.REQUEST:
            return {
                ...state,
                fetching: true
            };
        case AdvertisingActionTypes.GET.SUCCESS:
            return {
                ...state,
                data: action.payload,
                fetching: false
            };
        case AdvertisingActionTypes.GET.ERROR:
            return {
                ...state,
                error: true,
                errorText: action.payload,
                fetching: false
            };
        case AdvertisingActionTypes.LIST.REQUEST:
            return {
                ...state,
                fetching: true
            };
        case AdvertisingActionTypes.LIST.SUCCESS:
            return {
                ...state,
                list: action.payload,
                fetching: false
            };
        case AdvertisingActionTypes.LIST.ERROR:
            return {
                ...state,
                error: true,
                errorText: action.payload,
                fetching: false
            };
        default:
            return state;
    }
}

export default advertisingReducer;