import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'cookies-js';
import { Switch } from 'react-router-dom';
import { AuthInit } from "../Auth/auth.actions";
import Members from "../Members/members";
import MembersCreate from "../Members/members.create";
import MembersEdit from "../Members/members.edit";
import AppRouter from './../../AppRoute';
import PersonalLayout from './../../layouts/PersonalLayout';
import AdminLayout from './../../layouts/AdminLayout';
import NotFound from './notFound';
import AdvertisingCreate from "../Advertising/advertising.create";
import AdvertisingEdit from "../Advertising/advertising.edit";
import Advertising from "../Advertising/advertising";
import BakeryCreate from "../Bakery/bakery.create";
import BakeryEdit from "../Bakery/bakery.edit";
import Bakery from "../Bakery/bakery";
import NotificationCreate from "../Notification/notification.create";
import NotificationEdit from "../Notification/notification.edit";
import Notification from "../Notification/notification";
import Category from "../Categories/categories";

class Main extends Component {
  constructor(props) {
    super(props);

    const { profileActions } = props;

    let accessToken = Cookies.get('access_token');

    if (accessToken) {
      profileActions.AuthInit();
    } else {
      //profileActions.ProfileGoAuth("/auth");
      this.props.history.push('/auth');
    }
  }

  render() {
    return (
      <>
        <Switch>
            <AppRouter path='/members/create' component={MembersCreate} layout={PersonalLayout} />
            <AppRouter path='/members/edit/:id' component={MembersEdit} layout={PersonalLayout} />
            <AppRouter path='/members' component={Members} layout={PersonalLayout} />
            <AppRouter path='/advertising/create' component={AdvertisingCreate} layout={PersonalLayout} />
            <AppRouter path='/advertising/edit/:id' component={AdvertisingEdit} layout={PersonalLayout} />
            <AppRouter path='/advertising' component={Advertising} layout={PersonalLayout} />
            <AppRouter path='/bakery/create' component={BakeryCreate} layout={PersonalLayout} />
            <AppRouter path='/bakery/edit/:id' component={BakeryEdit} layout={PersonalLayout} />
            <AppRouter path='/bakery' component={Bakery} layout={PersonalLayout} />
            <AppRouter path='/notification/create' component={NotificationCreate} layout={PersonalLayout} />
            <AppRouter path='/notification/edit/:id' component={NotificationEdit} layout={PersonalLayout} />
            <AppRouter path='/notification' component={Notification} layout={PersonalLayout} />
            <AppRouter path='/category' component={Category} layout={PersonalLayout} />
            <AppRouter path='/notFound' component={NotFound} layout={AdminLayout} />
        </Switch>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    profileActions: bindActionCreators({AuthInit}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
