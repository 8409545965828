export const NotificationActionTypes = {
    LIST: {
        REQUEST: "NOTIFICATION_LIST_REQUEST",
        SUCCESS: "NOTIFICATION_LIST_SUCCESS",
        ERROR: "NOTIFICATION_LIST_ERROR"
    },
    GET: {
        REQUEST: "NOTIFICATION_GET_REQUEST",
        SUCCESS: "NOTIFICATION_GET_SUCCESS",
        ERROR: "NOTIFICATION_GET_ERROR"
    },
    ADD: {
        REQUEST: "NOTIFICATION_ADD_REQUEST",
        SUCCESS: "NOTIFICATION_ADD_SUCCESS",
        ERROR: "NOTIFICATION_ADD_ERROR"
    },
    DELETE: {
        REQUEST: "NOTIFICATION_DELETE_REQUEST",
        SUCCESS: "NOTIFICATION_DELETE_SUCCESS",
        ERROR: "NOTIFICATION_DELETE_ERROR"
    },
    UPDATE: {
        REQUEST: "NOTIFICATION_UPDATE_REQUEST",
        SUCCESS: "NOTIFICATION_UPDATE_SUCCESS",
        ERROR: "NOTIFICATION_UPDATE_ERROR"
    },
    CHANGE:{
        DATA_FIELD: "NOTIFICATION_CHANGE_DATA_FIELD",
        RESET: "NOTIFICATION_CHANGE_DATA_RESET"
    }
}
