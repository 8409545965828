import { ReactComponent as EditIcon } from './../../assets/images/useful/svg/edit.svg';
import { ReactComponent as DeleteIcon } from './../../assets/images/useful/svg/cross.svg';
import {Link} from "react-router-dom";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {DeleteNotification} from "./notification.actions";
import ModalConfirmDelete from "../../components/modals/modalConfirmDelete";

export const NotificationTableRow = (props) => {
    return(
        <tr>
            <td>{props.date}</td>
            <td>{props.name}</td>
            <td>{props.description}</td>
            <td style={{textAlign: 'right'}}>
                <Link to={'/notification/edit/' + props.id}><EditIcon/></Link>
                <DeleteIcon onClick={() => props.onDelete(props.id)}/>
            </td>
        </tr>
    );
}

export const NotificationTable = (props) => {
    const [selectedItem, setSelectedItem] = useState('');
    const [isDisplayModal, setIsDisplayModal] = useState(false);
    const dispatch = useDispatch();

    const handleOnDelete = (id) => {
        setSelectedItem(id);
        setIsDisplayModal(true);
    }

    const handleOnConfirmDelete = () => {
        dispatch(DeleteNotification(selectedItem));
        setSelectedItem('');
        setIsDisplayModal(false);
    }

    return(
        <>
            <table id="customers">
                <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Заголовок</th>
                        <th>Текст</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {
                    props.items && props.items.map((item, i) => {
                        return(
                            <NotificationTableRow key={i} {...item} onDelete={handleOnDelete}/>
                        );
                    })
                }
                </tbody>
            </table>
            <ModalConfirmDelete
                isDisplay={isDisplayModal}
                onClose={() => setIsDisplayModal(false)}
                onSubmit={handleOnConfirmDelete}
                buttonText="Удалить"
                title="Удалить уведомление?"
                text="Это действие нельзя будет отменить."
            />
        </>
    );
}
