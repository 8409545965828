export const NewsActionTypes = {
    LIST: {
        REQUEST: "NEWS_LIST_REQUEST",
        SUCCESS: "NEWS_LIST_SUCCESS",
        ERROR: "NEWS_LIST_ERROR"
    },
    GET: {
        REQUEST: "NEWS_GET_REQUEST",
        SUCCESS: "NEWS_GET_SUCCESS",
        ERROR: "NEWS_GET_ERROR"
    },
    ADD: {
        REQUEST: "NEWS_ADD_REQUEST",
        SUCCESS: "NEWS_ADD_SUCCESS",
        ERROR: "NEWS_ADD_ERROR"
    },
    DELETE: {
        REQUEST: "NEWS_DELETE_REQUEST",
        SUCCESS: "NEWS_DELETE_SUCCESS",
        ERROR: "NEWS_DELETE_ERROR"
    },
    UPDATE: {
        REQUEST: "NEWS_UPDATE_REQUEST",
        SUCCESS: "NEWS_UPDATE_SUCCESS",
        ERROR: "NEWS_UPDATE_ERROR"
    },
    CHANGE:{
        DATA_FIELD: "NEWS_CHANGE_DATA_FIELD",
        RESET: "NEWS_CHANGE_DATA_RESET"
    }
}
