export function checkTime(i) {
    return (i < 10) ? "0" + i : i;
}

export function getDateFormatted(data) {

    let day = checkTime(data.getDate());
    let mon = checkTime(data.getMonth() + 1);
    let year = checkTime(data.getFullYear());

    return `${day}/${mon}/${year}`;
}

export function getTimeFormatted(data) {

    let h = checkTime(data.getHours());
    let m = checkTime(data.getMinutes());
    let s = checkTime(data.getSeconds());
    return `${h}:${m}:${s}`;
}

export function getDate(data) {
    return getDateFormatted(new Date(Date.parse(data)));
}