import { FeedbackActionTypes } from "./feedback.types";

const INITIAL_STATE = {
    data: {},
    moderateList: [],
    declinedList: [],
    approvedList: [],
    error: false,
    errorText: "",
    fetching: false
}

const feedbackReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FeedbackActionTypes.GET.REQUEST:
            return {
                ...state,
                fetching: true
            };
        case FeedbackActionTypes.GET.SUCCESS:
            return {
                ...state,
                data: action.payload,
                fetching: false
            };
        case FeedbackActionTypes.GET.ERROR:
            return {
                ...state,
                error: true,
                errorText: action.payload,
                fetching: false
            };
        case FeedbackActionTypes.MODERATE_LIST.REQUEST:
            return {
                ...state,
                fetching: true
            };
        case FeedbackActionTypes.MODERATE_LIST.SUCCESS:
            return {
                ...state,
                moderateList: action.payload,
                fetching: false
            };
        case FeedbackActionTypes.MODERATE_LIST.ERROR:
            return {
                ...state,
                error: true,
                errorText: action.payload,
                fetching: false
            };
        case FeedbackActionTypes.DECLINED_LIST.REQUEST:
            return {
                ...state,
                fetching: true
            };
        case FeedbackActionTypes.DECLINED_LIST.SUCCESS:
            return {
                ...state,
                declinedList: action.payload,
                fetching: false
            };
        case FeedbackActionTypes.DECLINED_LIST.ERROR:
            return {
                ...state,
                error: true,
                errorText: action.payload,
                fetching: false
            };
        case FeedbackActionTypes.APPROVED_LIST.REQUEST:
            return {
                ...state,
                fetching: true
            };
        case FeedbackActionTypes.APPROVED_LIST.SUCCESS:
            return {
                ...state,
                approvedList: action.payload,
                fetching: false
            };
        case FeedbackActionTypes.APPROVED_LIST.ERROR:
            return {
                ...state,
                error: true,
                errorText: action.payload,
                fetching: false
            };
        default:
            return state;
    }
}

export default feedbackReducer;
