import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as InstitutionIcon } from './../assets/images/useful/svg/institution-menu.svg';
import { ReactComponent as ExitIcon } from './../assets/images/useful/svg/exit.svg';
import { ReactComponent as EmptyList } from './../assets/images/useful/svg/empty-list.svg';
import { ReactComponent as FeedbackIcon } from './../assets/images/useful/svg/feedback.svg';

import { ProfileLogout } from './../pages/Auth/auth.actions';

const WorkerMenu = props => {
  const dispatch = useDispatch();

  return (
    <div className='c-menu'>
      <h3 className='e-title--sm'>Меню</h3>
      <div className='c-menu__wrapper'>
        <ul className='h-reset-list c-menu__list'>

            <li className='c-menu__item'>
                <a href='/worker' className='c-menu__link'>
                    <InstitutionIcon className='c-menu__ico' />
                    <span className='c-menu__txt'>Учреждение</span>
                </a>
            </li>

          <li className='c-menu__item'>
            <a href='/worker/protocols' className='c-menu__link'>
              <EmptyList className='c-menu__ico' />
              <span className='c-menu__txt'>Протоколы</span>
            </a>
          </li>
        </ul>
        <ul className='h-reset-list c-menu__list'>
          <li className='c-menu__item'>
            <a href='/worker/feedback' className='c-menu__link'>
              <FeedbackIcon className='c-menu__ico' />
              <span className='c-menu__txt'>Обратная связь</span>
            </a>
          </li>
          <li className='c-menu__item'>
            <a href='#' className='c-menu__link' onClick={() => dispatch(ProfileLogout())}>
              <ExitIcon className='c-menu__ico' style={{ width: '15px' }} />
              <span className='c-menu__txt'>Выход</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WorkerMenu;
