import {CategoriesTypes} from "./categories.types";

const INITIAL_STATE = {
    list: [],
    error: false,
    errorText: "",
    fetching: false,
}

const categoriesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CategoriesTypes.LIST.REQUEST:
            return {
                ...state,
                fetching: true
            };
        case CategoriesTypes.LIST.SUCCESS:
            return {
                ...state,
                list: action.payload,
                fetching: false
            };
        case CategoriesTypes.LIST.ERROR:
            return {
                ...state,
                error: true,
                errorText: action.payload,
                fetching: false
            };
        default:
            return state;
    }
}

export default categoriesReducer;
