import {AdvertisingTable} from "./advertising.table";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {ListAdvertisingData} from "./advertising.actions";

const AdvertisingHeader = () => {
    return(
        <div className="c-banners__header">
            <div className="c-banners__header-header">
                <h4>Акции</h4>
            </div>
        </div>
    );
}

const Advertising = () => {
    const advertising = useSelector(state => state.advertising);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isLoading){
            dispatch(ListAdvertisingData())
            setIsLoading(true);
        }
    })

    return(
        <div className="c-banners">
            <AdvertisingHeader/>
            <div className="c-banners__item-wrapper">
                <AdvertisingTable items={advertising.list} postion={advertising.list}/>
                <div className="c-banners__item-button">
                    <Link className="e-btn e-btn--filled" to={`/advertising/create`}>Добавить</Link>
                </div>
            </div>
        </div>
    );
}

export default Advertising;
