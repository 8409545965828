import {NotificationActionTypes} from "./notification.types";

const INITIAL_STATE = {
    data: {},
    list: [],
    error: false,
    errorText: "",
    fetching: false,
}

const notificationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NotificationActionTypes.GET.REQUEST:
            return {
                ...state,
                fetching: true
            };
        case NotificationActionTypes.GET.SUCCESS:
            return {
                ...state,
                data: action.payload,
                fetching: false
            };
        case NotificationActionTypes.GET.ERROR:
            return {
                ...state,
                error: true,
                errorText: action.payload,
                fetching: false
            };
        case NotificationActionTypes.LIST.REQUEST:
            return {
                ...state,
                fetching: true
            };
        case NotificationActionTypes.LIST.SUCCESS:
            return {
                ...state,
                list: action.payload,
                fetching: false
            };
        case NotificationActionTypes.LIST.ERROR:
            return {
                ...state,
                error: true,
                errorText: action.payload,
                fetching: false
            };
        case NotificationActionTypes.CHANGE.DATA_FIELD:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.field]: action.payload.value
                }
            }
        case NotificationActionTypes.CHANGE.RESET:
            return {
                ...state,
                data: {
                }
            }
        default:
            return state;
    }
}

export default notificationReducer;
