import {BakeryTable} from "./bakery.table";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {ListBakeryData} from "./bakery.actions";

const BakeryHeader = () => {
    return(
        <div className="c-banners__header">
            <div className="c-banners__header-header">
                <h4>Адреса пекарен</h4>
            </div>
        </div>
    );
}

const Bakery = () => {
    const bakeries = useSelector(state => state.bakeries);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isLoading){
            dispatch(ListBakeryData())
            setIsLoading(true);
        }
    })

    return(
        <div className="c-banners">
            <BakeryHeader/>
            <div className="c-banners__item-wrapper">
                <BakeryTable items={bakeries.list} postion={bakeries.list}/>
                <div className="c-banners__item-button">
                    <Link className="e-btn e-btn--filled" to={`/bakery/create`}>Добавить</Link>
                </div>
            </div>
        </div>
    );
}

export default Bakery;
