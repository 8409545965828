import InputPublic from "../../components/inputPublic";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ValidatePhoneField, ValidateTextField} from "../../utils/validation";
import InputFile from "../../components/inputFile";
import {AddBakery} from "./bakery.actions";
import InputCheckbox from "../../components/inputCheckbox";

const BakeryCreate = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [street, setStreet] = useState('');
    const [streetValid, setStreetValid] = useState(true);
    const [streetError, setStreetError] = useState("");
    const [city, setCity] = useState('');
    const [cityValid, setCityValid] = useState(true);
    const [cityError, setCityError] = useState("");
    const [phone, setPhone] = useState('');
    const [phoneValid, setPhoneValid] = useState(true);
    const [phoneError, setPhoneError] = useState("");
    const [hours, setHours] = useState('');
    const [hoursValid, setHoursValid] = useState(true);
    const [hoursError, setHoursError] = useState("");
    const [longitude, setLongitude] = useState('');
    const [longitudeValid, setLongitudeValid] = useState(true);
    const [longitudeError, setLongitudeError] = useState("");
    const [latitude, setLatitude] = useState('');
    const [latitudeValid, setLatitudeValid] = useState(true);
    const [latitudeError, setLatitudeError] = useState("");
    const [files, setFiles] = useState([]);
    const [fileName, setFileName] = useState('');
    const [isActive, setIsActive] = useState(false);

    useEffect(() => { console.log(files)}, [files])

    const handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        switch (name){
            case "city":
                setCity(value);
                const cityValidationResult = ValidateTextField(value);
                setCityValid(cityValidationResult.isValid);
                setCityError(cityValidationResult.message);
                break;
            case "street":
                setStreet(value);
                const streetValidationResult = ValidateTextField(value);
                setStreetValid(streetValidationResult.isValid);
                setStreetError(streetValidationResult.message);
                break;
            case "phone":
                setPhone(value);
                const phoneValidationResult = ValidateTextField(value);
                setPhoneValid(phoneValidationResult.isValid);
                setPhoneError(phoneValidationResult.message);
                break;
            case "hours":
                setHours(value);
                const hoursValidationResult = ValidateTextField(value);
                setHoursValid(hoursValidationResult.isValid);
                setHoursError(hoursValidationResult.message);
                break;
            case "longitude":
                setLongitude(value);
                const longitudeValidationResult = ValidateTextField(value);
                setLongitudeValid(longitudeValidationResult.isValid);
                setLongitudeError(longitudeValidationResult.message);
                break;
            case "latitude":
                setLatitude(value);
                const latitudeValidationResult = ValidateTextField(value);
                setLatitudeValid(latitudeValidationResult.isValid);
                setLatitudeError(latitudeValidationResult.message);
                break;
        }
    }

    const onHandleSubmit = () => {
        let validationResult = validateForm();
        if (validationResult === true) {
            dispatch(AddBakery(files, city, street, phone, hours, latitude, longitude, isActive, history));
        }
    }

    const onSelectFile = (e, i) => {
        let fileList = files;

        if (e.target.files && e.target.files.length > 0){
            fileList.splice(i, 1, e.target.files[0])
            setFiles(fileList);
        }
    }

    const onAddNewFile = () => {
        let fileList = files;
        fileList.push({
            name: ''
        });
        setFiles(fileList);
    }

    const onDeleteFile = (i) => {
        let fileList = files;
        fileList.splice(i, 1)
        setFiles(fileList);
    }

    function validateForm(){
        let emptyFields = false;
        if (!city){
            setCityValid(false);
            setCityError("Не указано город");
            emptyFields = true;
        }

        if (!street){
            setStreetValid(false);
            setStreetError("Не указан адрес");
            emptyFields = true;
        }

        if (!phone){
            setPhoneValid(false);
            setPhoneError("Не указан телефон");
            emptyFields = true;
        }

        if (!hours){
            setHoursValid(false);
            setHoursError("Не указаны часы работы");
            emptyFields = true;
        }

        if (!latitude){
            setLatitudeValid(false);
            setLatitudeError("Не указана широта");
            emptyFields = true;
        }

        if (!longitude){
            setLongitudeValid(false);
            setLongitudeError("Не указана долгота");
            emptyFields = true;
        }

        return !emptyFields && cityValid && streetValid && phoneValid && hoursValid && latitudeValid && longitudeValid;
    }

    return(
        <div className="c-banners">
            <h3>Пекарни / Добавить пекарню</h3>
            <form id="c-entry__login" className="c-entry__edit-form">
                <div className="c-entry__line">
                    <InputPublic
                        title={'Город'}
                        name={'city'}
                        type={'text'}
                        placeholder={''}
                        error={!cityValid}
                        errorText={cityError}
                        data={city}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line">
                    <InputPublic
                        title={'Улица'}
                        name={'street'}
                        type={'text'}
                        placeholder={''}
                        error={!streetValid}
                        errorText={streetError}
                        data={street}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line">
                    <InputPublic
                        title={'Телефон'}
                        name={'phone'}
                        type={'phone'}
                        placeholder={''}
                        error={!phoneValid}
                        errorText={phoneError}
                        data={phone}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line">
                    <InputPublic
                        title={'Часы работы'}
                        name={'hours'}
                        type={'text'}
                        placeholder={''}
                        error={!hoursValid}
                        errorText={hoursError}
                        data={hours}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line">
                    <InputPublic
                        title={'Широта'}
                        name={'latitude'}
                        type={'text'}
                        placeholder={''}
                        error={!latitudeValid}
                        errorText={latitudeError}
                        data={latitude}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line">
                    <InputPublic
                        title={'Долгота'}
                        name={'longitude'}
                        type={'text'}
                        placeholder={''}
                        error={!longitudeValid}
                        errorText={longitudeError}
                        data={longitude}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line">
                    <label className="e-label">Фото пекарни</label>
                </div>
                <div className="c-entry__line">
                    {
                        files.map((item, i) => {
                            console.log(item)
                            return(
                                <>
                                    <InputFile key={i} fileName={item.name} onSelectFile={e => onSelectFile(e, i)}/>
                                    <span onClick={() => onDeleteFile(i)}>X</span>
                                </>
                            );
                        })
                    }
                </div>
                <div className="c-entry__line c-entry__line--mt">
                    <button type="button" className="e-btn e-btn--filled" onClick={onAddNewFile}>Добавить еще изображение</button>
                </div>
                <div className="c-entry__line">
                    <InputCheckbox title="Скрыть запись из каталогов" isSelected={isActive} onChange={setIsActive}/>
                </div>
                <div className="c-entry__line c-entry__line--mt">
                    <button type="button" className="e-btn e-btn--filled" onClick={onHandleSubmit}>Добавить</button>
                </div>
            </form>
        </div>
    );

}

export default BakeryCreate;
