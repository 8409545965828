import React from 'react';
import { ReactComponent as ExitIcon } from "./../assets/images/useful/svg/close-circle.svg";
import { store } from 'react-notifications-component';

export function ShowNotification(title, errorText, isError) {
    store.addNotification({
        content: <ErrorNotification title={title} errorText={errorText} isError={isError} />,
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 3000
        }
    });
}

const ErrorNotification = (props) => (
    <div className="e-error-notification-wrapper">
        <div className="e-error-notification-left">
            <span className={props.isError ? "e-error-notification-error" : "e-error-notification-success"}>{props.title}</span>
            <p>{props.errorText}</p>
        </div>
        <div className="e-error-notification-right">
            <ExitIcon style={{ marginTop: '40%' }} />
        </div>
    </div>
);

export default ErrorNotification;