import { useSelector, useDispatch } from 'react-redux';
import {useEffect, useState} from "react";
import {ListMemberData} from "./members.actions";
import {MembersTable} from "./members.table";
import {Link} from "react-router-dom";

const MembersHeader = () => {
    return(
        <div className="c-members__header">
            <div className="c-members__header-header">
                <h4>Администраторы</h4>
            </div>
            <div className="c-members__header-button">
                <Link className="e-btn e-btn--filled" to="/members/create">Добавить</Link>
            </div>
        </div>
    );
}

const Members = () => {
    const members = useSelector(state => state.members);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        if (!isLoading){
            dispatch(ListMemberData());
            setIsLoading(true);
        }
    })

    return(
        <div className="c-members">
            <MembersHeader/>
            <MembersTable items={members.list}/>
        </div>
    );
}

export default Members;
