import { NewsActionTypes } from './news.types'

const INITIAL_STATE = {
    data: {},
    list:[],
    error: false,
    errorText: "",
    fetching: false
}

const bannersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NewsActionTypes.GET.REQUEST:
            return {
                ...state,
                fetching: true
            };
        case NewsActionTypes.GET.SUCCESS:
            return {
                ...state,
                data: action.payload,
                fetching: false
            };
        case NewsActionTypes.GET.ERROR:
            return {
                ...state,
                error: true,
                errorText: action.payload,
                fetching: false
            };
        case NewsActionTypes.LIST.REQUEST:
            return {
                ...state,
                fetching: true
            };
        case NewsActionTypes.LIST.SUCCESS:
            return {
                ...state,
                list: action.payload,
                fetching: false
            };
        case NewsActionTypes.LIST.ERROR:
            return {
                ...state,
                error: true,
                errorText: action.payload,
                fetching: false
            };
        default:
            return state;
    }
}

export default bannersReducer;
