import React, { useState } from 'react';
import SymbolDefs from './../assets/images/useful/svg/theme/symbol-defs.svg';

const InputCheckbox = (props) => {
    const { isSelected } = props;

    const handleOnChange = () => {
        props.onChange(!isSelected);
    }
    return (
        <div className="c-modal-select-role__action-block">
            {
                props.labelTitle &&
                <label htmlFor="autocomplete-city" className="e-label">{props.labelTitle}</label>
            }            
            <div className="e-role">
                <input type="checkbox" className="e-checkbox" checked={isSelected} onChange={handleOnChange} />
                <span>{props.title}</span>
            </div>
            {
                props.isDisplayTooltip &&
                <div className="e-tooltip">
                <span className="e-tooltip__title">{props.tooltipTitle}</span>
                <button type="button" className="e-tooltip__btn">
                    <svg width="1em" height="1em" className="icon icon-question ">
                        <use xlinkHref={`${SymbolDefs}#icon-question`} ></use>
                    </svg>
                    <div className="e-tooltip__wrapper">
                        <p className="e-tooltip__text">{props.tooltipText}</p>
                    </div>
                </button>
            </div>
            }
            {
                props.warningTitle &&
                <div className="e-warning">{props.warningTitle}</div>
            }
        </div>
    );
}

export default InputCheckbox;