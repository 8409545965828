import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const Menu = props => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
  }, [auth]);

  useEffect(() => {
      console.log(window.location.pathname)
      setSelected(window.location.pathname);
  }, [])

  return (
      <div className='left-side'>
          <div className='mobile-burger'>
              <div className='mobile-burger__head'>
                  <button type='button' className='mobile-burger__btn js-close-mmenu'>
                      <span></span>
                  </button>
              </div>
              <div className='mobile-burger__content'>
    <div className='c-menu'>
      <div className='c-menu__wrapper'>
        <ul className='h-reset-list c-menu__list'>
            <li className='c-menu__item'>
                <a href='/advertising' className='c-menu__link'>
              <span className={`c-menu__txt ${selected === "/advertising" ? "c-menu__selected" : ""}`}>
                Акции (Главная)
              </span>
                </a>
            </li>
            <li className='c-menu__item'>
                <a href='/bakery' className='c-menu__link'>
                  <span className={`c-menu__txt ${selected === "/bakery" ? "c-menu__selected" : ""}`}>
                    Адреса пекарен
                  </span>
                </a>
            </li>
            <li className='c-menu__item'>
                <a href='/notification' className='c-menu__link'>
                  <span className={`c-menu__txt ${selected === "/notification" ? "c-menu__selected" : ""}`}>
                    Уведомления
                  </span>
                </a>
            </li>
            <li className='c-menu__item'>
                <a href='/category' className='c-menu__link'>
                  <span className={`c-menu__txt ${selected === "/category" ? "c-menu__selected" : ""}`}>
                    Категории
                  </span>
                </a>
            </li>
              <li className='c-menu__item'>
                <a href='/members' className='c-menu__link'>
                  <span className={`c-menu__txt ${selected === "/members" ? "c-menu__selected" : ""}`}>
                    Администраторы
                  </span>
                </a>
              </li>
        </ul>
      </div>
    </div></div></div>
      </div>
  );
};

export default Menu;
