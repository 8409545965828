import {useDispatch} from "react-redux";
import {UpdateCategory} from "./categories.actions";
import React, {useState} from "react";
import CatalogIcon1 from '../../assets/images/useful/svg/Categories-1.svg';
import CatalogIcon2 from '../../assets/images/useful/svg/Categories-2.svg';
import CatalogIcon3 from '../../assets/images/useful/svg/Categories-3.svg';
import CatalogIcon4 from '../../assets/images/useful/svg/Categories-4.svg';
import CatalogIcon5 from '../../assets/images/useful/svg/Categories-5.svg';
import CatalogIcon6 from '../../assets/images/useful/svg/Categories-6.svg';
import CatalogIcon7 from '../../assets/images/useful/svg/Categories-7.svg';
import InputFile from "../../components/inputFile";

export const CategoriesTableRow = (props) => {
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileUrl, setSelectedFileUrl] = useState('');
    const [selectedFileName, setSelectedFileName] = useState('');

    const handleOnChangeNomenclature = () => {
        props.onUpdate(props.id, file, selectedFile);
    }

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0){
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);
            setFileUrl('');
        }
    }

    const onSelectSelectedFile = (e) => {
        if (e.target.files && e.target.files.length > 0){
            setSelectedFile(e.target.files[0]);
            setSelectedFileName(e.target.files[0].name);
            setSelectedFileUrl('');
        }
    }

    return(
        <tr>
            <td>{props.name}</td>
            <td>
                {props.image && <img src={props.image}/>}
            </td>
            <td>
                <InputFile fileUrl={fileUrl} fileName={fileName} onSelectFile={e => onSelectFile(e)} isSmall={true}/>
            </td>
            <td>
                {props.selectedImage && <img src={props.selectedImage}/>}
            </td>
            <td>
                <InputFile fileUrl={selectedFileUrl} fileName={selectedFileName} onSelectFile={e => onSelectSelectedFile(e)} isSmall={true}/>
            </td>
            <td>
                <button type="button" className="e-btn-sm e-btn--filled" onClick={handleOnChangeNomenclature}>Сохранить</button>
            </td>
        </tr>
    );
}

export const CategoriesTable = (props) => {
    const dispatch = useDispatch();

    const handleOnChangeIcon = (id, file, selectedFile) => {
        console.log(id, file, selectedFile)
        dispatch(UpdateCategory(id, file, selectedFile));
    }

    return(
        <>
            <table id="customers">
                <thead>
                    <tr>
                        <th>Категория</th>
                        <th colSpan={2}>Иконка</th>
                        <th colSpan={2}>Иконка для выбранной категории</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {
                    props.items && props.items.map((item, i) => {
                        return(
                            <CategoriesTableRow key={i} name={item.categoryName} id={item.categoryId} image={item.imageUrl}
                                                selectedImage={item.selectedImageUrl}
                                                onUpdate={handleOnChangeIcon}/>
                        );
                    })
                }
                </tbody>
            </table>
        </>
    );
}
