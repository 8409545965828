export const AUTH_FORGOT_PASSWORD_REQUEST = 'AUTH_FORGOT_PASSWORD_REQUEST';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS';
export const AUTH_FORGOT_PASSWORD_ERROR = 'AUTH_FORGOT_PASSWORD_ERROR';
export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_REFRESH_REQUEST = 'AUTH_REFRESH_REQUEST';
export const AUTH_REFRESH_SUCCESS = 'AUTH_REFRESH_SUCCESS';
export const AUTH_REFRESH_ERROR = 'AUTH_REFRESH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_PROFILE_CHANGE = 'AUTH_PROFILE_CHANGE';
export const AUTH_REGISTER_REQUEST = 'AUTH_REGISTER_REQUEST';
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
export const AUTH_REGISTER_ERROR = 'AUTH_REGISTER_ERROR';
export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_ERROR = 'AUTH_RESET_PASSWORD_ERROR';
export const AUTH_CONFIRM_EMAIL_REQUEST = 'AUTH_CONFIRM_EMAIL_REQUEST';
export const AUTH_CONFIRM_EMAIL_SUCCESS = 'AUTH_CONFIRM_EMAIL_SUCCESS';
export const AUTH_CONFIRM_EMAIL_ERROR = 'AUTH_CONFIRM_EMAIL_ERROR';
export const AUTH_CHANGE_PASSWORD_REQUEST = 'AUTH_CHANGE_PASSWORD_REQUEST';
export const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS';
export const AUTH_CHANGE_PASSWORD_ERROR = 'AUTH_CHANGE_PASSWORD_ERROR';
export const AUTH_CHANGE_ROLE = 'AUTH_CHANGE_ROLE';